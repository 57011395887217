.component--chat {
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url('https://i.pinimg.com/564x/98/38/d7/9838d76ad6ed5a30ae1548b30b865a63.jpg');
    display: flex;
    flex-direction: column;
}

.component--chat .bottom {
    position: absolute;
    right: 30px;
    bottom: 160px;
}

.component--chat .bottom .icon {
    font-size: 40px;
    cursor: pointer;
}

.component--chat .bottom .icon:hover {
    color: rgb(255, 125, 0);
}

.component--chat .notification-sound {
    display: none;
}

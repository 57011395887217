.component--modal {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(50, 50, 50, 0.80);
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.component--modal > .window {
    border-style: outset;
    border-width: 1px;
    border-color: white;
    border-radius: 10px;
    background-color: white;
    color: black;
    align-self: center;
    display: flex;
    flex-direction: column;
}

.component--modal > .window > .title > h4 {
    margin: 10px;
}

.component--modal > .window > .separator > hr {
    margin: 0;
}

.component--modal > .window > .body {
    flex-grow: 1;
}

.component--modal > .window > .commands {
    margin: 10px;
    display: flex;
    justify-content: space-between;
}

.component--modal > .window > .commands > button {
    width: 100px;
    padding: 5px 10px;
    cursor: pointer;
    border: 0;
    border-radius: 5px;
}

.component--modal > .window > .commands > button > span {
    margin-left: 10px;
}

.component--modal > .window > .commands > button.cancelButton {
    background-color: red;
    color: white;
}

.component--modal > .window > .commands > button.closeButton {
    background-color: gray;
    color: white;
}

.component--modal > .window > .commands > button.confirmButton {
    background-color: green;
    color: white;
}

.component--modal .modal-form-field {
    padding: 10px 20px 5px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.component--modal .modal-form-field.half {
    display: inline-block;
    width: 50%;
}

.component--modal .modal-form-field > label {
    display: block;
    font-size: smaller;
    margin-bottom: 2px;
}

.component--modal .modal-form-field > input {
    display: block;
    box-sizing: border-box;
    margin: 0;
    border: 0;
    padding: 2px 5px;
    width: 100%;
}

.component--channel {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.component--channel .left, .component--channel .right {
    display: inline-block;
    width: 300px;
    overflow-y: scroll;
}

.component--channel .center {
    position: relative;
    display: inline-block;
    flex-grow: 1;
}

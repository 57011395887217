.post {
    margin-bottom: 10px;
}

.post .time {
    font-size: 12px;
    margin-right: 10px;
    color: dimgray;
    font-weight: bold;
}

.post .actor {
    font-size: 14px;
    margin-right: 10px;
    font-weight: bold;
}

.post-action .actor {
    margin-right: 5px;
}

.post .gender-icon {
    margin-right: 5px;
}

.post .gender-icon.male {
    color: #15aabf;
}

.post .gender-icon.female {
    color: #be4bdb;
}

.post .content {
    font-size: 14px;
}

.post-action .content {
    font-weight: bold;
}

.post-bookmark {
    margin-top: 10px;
    border-bottom: 2px solid black;
}

.post-bookmark .content {
    font-size: x-large;
    font-weight: bold;
    vertical-align: middle;
    margin-left: 10px;
}

.post-bookmark .icon {
    vertical-align: middle;
}

.post-chapter {
    text-align: center;
    margin-top: 10px;
    border-bottom: 2px solid black;
}

.post-chapter .content {
    font-size: xx-large;
    font-weight: bold;
    vertical-align: middle;
    margin-left: 10px;
}

.post-chapter .icon {
    vertical-align: middle;
}

.post-narration {
    padding: 10px 20px;
    background-color: #000000;
    color: white;
    margin: 0 -20px 10px;
}

.post-image {
    text-align: center;
}

.post-image .caption {
    font-size: smaller;
}

.post-note {
    border: 1px dotted black;
    padding: 10px 20px;
    background-color: rgba(255, 255, 0, 0.75);
    color: #000000;
}

.post-system {
    border: 1px dotted black;
    padding: 10px 20px;
    background-color: rgba(255, 75, 0, 0.75);
    color: #000000;
}

.post-system .time {
    color: black;
    font-size: 12px;
}

.post-system .content {
    font-size: 12px;
}

.post-link {
    border: 1px dotted black;
    padding: 10px 20px;
    background-color: rgba(31, 255, 237, 0.75);
    color: #000000;
    text-align: center;
}

.post-link .link {
    color: black;
    font-weight: bold;
}

.post-link .caption {
    font-size: smaller;
    display: block;
}

.post .editIcon {
    margin: 0 2px;
    font-size: smaller;
    cursor: pointer;
}

.post .deleteIcon {
    margin: 0 2px;
    font-size: smaller;
    cursor: pointer;
}

.loginContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('./media/wallpaper.jpg');
    text-align: center;
}

.loginForm {
    position: relative;
    width: 300px;
    margin: 20px auto;
    border-radius: 20px;
    padding: 20px;
    font-size: smaller;
    text-align: center;
}

.loginIncipit {
    position: relative;
    color: #ffffff;
}

.loginIncipit h1 {
    margin: 10px;
    font-size: 72px;
    text-shadow: 0px 0px 10px #000000;
}

.loginIncipit h4 {
    margin: 10px;
    font-size: 36px;
    text-shadow: 0px 0px 10px #000000;
}

.loginInputField {
    width: 200px;
    margin: 0;
    border: 0;
    padding: 5px;
    background-color: transparent;
    font-style: italic;
    text-align: center;
    outline: none;
}

.loginInputFieldLabel {
    font-size: x-small;
    text-transform: uppercase;
    display: block;
}

.loginInputButton {
    padding: 10px 20px;
    background-color: #1d1913;
    font-size: smaller;
    color: white;
    cursor: pointer;
    outline: none;
    border: 1px solid black;
    border-radius: 5px;
}

.loginInputButton:hover {
    background-color: #534a38;
}

.loginMessagesContainer {
    padding: 10px;
    font-size: small;
    color: #ff001a;
    mix-blend-mode: difference;
}

.loginMessage {
    display: block;
}

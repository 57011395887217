.component--user-bar {
    z-index: 50;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    padding: 4px;
    align-items: center;
}

.component--user-bar-group {
    display: inline-block;
    vertical-align: middle;
    font-size: 1em;
}

.component--user-bar-text, .component--user-bar-button, .component--user-bar-switch {
    display: inline-block;
    padding: 5px;
    vertical-align: middle;
    font-size: 1em;
}

.component--user-bar-button, .component--user-bar-switch {
    filter: invert(35%);
}

.component--user-bar-button > svg, .component--user-bar-switch > svg {
    font-size: 1.25em;
    cursor: pointer;
}

.component--user-bar-button:hover, .component--user-bar-switch.active {
    filter: invert(0%);
}

.component--user-bar .profile {
    display: inline-block;
}

.component--user-bar .profile .username {
    vertical-align: middle;
    margin: 0 10px;
}

.component--user-bar .profile .image {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-size: 100% 100%;
    border-radius: 20px;
    vertical-align: middle;
}

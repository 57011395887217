.chat-commands {
    padding: 4px;
    position: relative;
}

.chat-commands .post-type-selector {
    display: inline-block;
}

.chat-commands .top-commands {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px;
}

.chat-commands .post-type {
    display: inline-block;
    border: 1px solid;
    padding: 2px 10px;
    font-size: 10px;
    cursor: pointer;
    width: 100px;
}

.chat-commands .post-type svg {
    vertical-align: center;
    margin-right: 5px;
}

.chat-commands .post-type span {
    vertical-align: center;
}

.chat-commands .selector {
    position: absolute;
    bottom: 3.5px;
    left: 126px;
    border: 1px solid black;
}

.chat-commands .selector .post-type {
    display: block;
    border: 0;
    border-bottom: 1px solid;
}

.chat-commands .selector .post-type:last-child {
    display: block;
    border-bottom: 0;
}

.chat-commands .post-type-selector {
    position: relative;
}

.chat-commands .content-form {
    position: relative;
    margin-top: -4px;
    padding: 5px;
}

.chat-commands .content-form svg {
    cursor: pointer;
    margin-right: 10px;
}

.chat-commands .content-form.content-only span {
    display: block;
    font-size: x-small;
    font-weight: bold;
    text-transform: uppercase;
}

.chat-commands .content-form.content-form textarea {
    box-sizing: border-box;
    width: 100%;
    padding: 5px 10px;
    font-family: "sans-serif", "Times New Roman";
    letter-spacing: 0.05rem;
    line-height: 1.2rem;
}

.chat-commands .content-form table {
    width: 100%;
}

.chat-commands .content-form table td.column-header {
    width: 100px;
    font-size: 10px;
    font-weight: bold;
    text-align: right;
    padding-right: 6px;
}

.chat-commands .content-form table td.column-value {
    padding-right: 6px;
    font-size: 12px;
}

.chat-commands .content-form table td.column-value input {
    margin-right: 10px;
}

.command-button {
    border: 1px solid rgba(100, 100, 100, 0.5);
    border-radius: 5px;
    padding: 4px 10px;
    cursor: pointer;
    font-size: small;
    width: 150px;
}
html, body {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    border: 0;
    padding: 0;
    overflow: hidden;
    font-family: sans-serif;
}

#root {
    position: absolute;
    width: 100%;
    height: 100%;
}

.bgGradientLight {
    background: rgb(254, 254, 248);
    background: linear-gradient(180deg, rgba(254,254,248,1) 0%, rgba(204,204,198,1) 100%);
    color: #000000;
}

.bgGradientIvory {
    background: rgb(254, 250, 199);
    background: linear-gradient(180deg, rgb(254, 250, 199) 0%, rgba(204,200,149,1) 100%);
    color: #000000;
}

.bgGradientPeach {
    background: rgb(254, 193, 163);
    background: linear-gradient(180deg, rgb(254, 193, 163) 0%, rgb(204, 143, 113) 100%);
    color: #000000;
}

.bgGradientNight {
    background: rgb(27, 36, 108);
    background: linear-gradient(180deg, rgb(27, 36, 108) 0%, rgb(0, 0, 20) 100%);
    color: #ffffff;
}

.bgGradientChocolate {
    background: rgb(53, 32, 7);
    background: linear-gradient(180deg, rgba(53, 32, 7) 0%, rgb(20, 10, 0) 100%);
    color: #ffffff;
}

.bgGradientDark {
    background: rgb(50, 50, 50);
    background: linear-gradient(180deg, rgb(50, 50, 50) 0%, rgb(20, 20, 20) 100%);
    color: #ffffff;
}

.bgLight { background-color: rgba(253, 255, 244, 0.5); color: #000000; }
.bgIvory { background-color: rgba(254, 239, 202, 0.5); color: #000000; }
.bgPeach { background-color: rgba(255, 222, 209, 0.5); color: #000000; }
.bgNight { background-color: rgba(0, 0, 32, 0.80); color: #C0C0C0; }
.bgChocolate { background-color: rgba(32, 16, 16, 0.80); color: #C0C0C0; }
.bgDark { background-color: rgba(32, 32, 32, 0.80); color: #C0C0C0; }

.titleLight { color: #303030; }
.titleIvory { color: #2e1d17; }
.titlePeach { color: #682730; }
.titleNight { color: #b8b560; }
.titleChocolate { color: #b8b560; }
.titleDark { color: #b8b560; }

.activeLight { color: #ff9000; }
.activeIvory { color: #ff9000; }
.activePeach { color: #ff001a; }
.activeNight { color: #1bb8b8; }
.activeChocolate { color: #1bb8b8; }
.activeDark { color: #1bb8b8; }

.textLight { color: #000000; }
.textIvory { color: #000000; }
.textPeach { color: #000000; }
.textNight { color: #ffffff; }
.textChocolate { color: #ffffff; }
.textDark { color: #ffffff; }


::-webkit-scrollbar {
    width: 16px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(139, 121, 79);
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(255, 141, 61);
}
#channel-selection-modal .element {
    width: 300px;
    padding: 10px 20px;
    font-size: 0.9em;
    text-align: center;
    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
    cursor: pointer;
    filter: invert(30%);
}

#channel-selection-modal .element:hover {
    filter: invert(0%);
}

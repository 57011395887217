.component--chat-filters {
    display: flex;
    padding: 10px 20px;
    text-align: right;
    justify-content: space-between;
    align-items: center;
}

.component--chat-filters-group {
    display: inline-block;
}

.component--chat-filters-switch {
    display: inline-block;
    margin: 0 5px;
    filter: invert(30%);
}

.component--chat-filters-switch.active {
    filter: invert(0%);
}

.component--chat-filters-switch > svg {
    cursor: pointer;
}

#theme-selection-modal .element {
    width: 200px;
    padding: 20px 20px;
    cursor: pointer;
    text-align: center;
}

#theme-selection-modal .grid {
    display: grid;
    grid-template-columns: auto auto auto;
}

#theme-selection-modal .element > span {
    filter: invert(50%);
}

#theme-selection-modal .element:hover > span {
    filter: invert(0%);
}

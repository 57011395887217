.component--layout {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.component--layout > .top, .component--layout > .bottom {
    display: inline-block;
    border: 0;
}

.component--layout > .middle {
    flex-grow: 1;
    display: inline-block;
    position: relative;
}

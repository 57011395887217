.component--widget {
    margin: 10px;
    border-radius: 10px;
    background-color: white;
    color: black;
}

.component--widget .title {
    border-radius: 10px 10px 0 0;
    padding: 5px 10px;
    font-weight: bold;
}

.component--widget .body {
    border-radius: 0 0 15px 15px;
    padding: 5px 10px;
    font-size: small;
}

.component--widget .body ul {
    margin: 0 5px;
    padding: 0 0 0 15px;
}